<template>
  <div style="position: relative!important;">
    <div>
      <div class="header-additional">
        <div class="container">
          <div class="header-additional-block">
            <div class="header-additional__logo">
              <img src="images/game.svg" width="44" height="">
              <div class="header-additional__desc">
                {{$t('ЭраНезависимости.название')}}
              </div>
            </div>
            <div style="display: flex;width: 70%; justify-content: flex-end;">
              <el-select
                  style="margin-right: 1rem"
                  @change="changeLang(false)"
                  :placeholder="$t('Qabilet.ВыберитеЯзык')"
                  v-model="locale"
                  filterable>
                <el-option
                    key="kz"
                    :label="$t('Qabilet.Казахский')"
                    value="kz">
                </el-option>
                <el-option
                    key="ru"
                    :label="$t('Qabilet.Русский')"
                    value="ru">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>

      <section class="mb-statistic">
        <div class="container">
          <div class="mb-statistic__title-row">
            <div class="filter-game-wrap">
              <div class="title">
                {{$t('МедальЕлбасы.Аудитория')}}
              </div>
              <div  v-if="locale=='ru'" class="select select--region ml-5">
                <el-select
                    @change="auditoryFilter(false)"
                    :placeholder="$t('Qabilet.Регион')"
                    v-model="auditoryFilterParams.region"
                    filterable>
                  <el-option
                      v-for="item in regions"
                      :key="item.id"
                      :label="item.title_ru"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div  v-if="locale=='kz'" class="select select--region ml-5">
                <el-select
                    @change="auditoryFilter(false)"
                    :placeholder="$t('Qabilet.Регион')"
                    v-model="auditoryFilterParams.region"
                    filterable>
                  <el-option
                      v-for="item in regions"
                      :key="item.id"
                      :label="item.title_kz"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="select select--region">
                <el-select
                    @change="auditoryFilter(false)"
                    v-model="auditoryFilterParams.ageFrom"
                    :placeholder="$t('ЭраНезависимости.возрастОт')"
                    filterable>
                  <el-option
                      v-for="item in 100"
                      :key="item+1"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="select select--region">
                <el-select
                    @change="auditoryFilter(false)"

                    v-model="auditoryFilterParams.ageTo"
                    :placeholder="$t('ЭраНезависимости.возрастДо')"
                    filterable>
                  <el-option
                      v-for="item in 100"
                      :key="item-1"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="select select--date select--region">
                <el-date-picker
                    @change="auditoryFilter(false)"
                    v-model="auditoryFilterParams.dateFrom"
                    class="select--date"
                    type="date"
                    :placeholder="$t('ЭраНезависимости.датаОт')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <div class="select select--date select--region">
                <el-date-picker
                    @change="auditoryFilter(false)"
                    v-model="auditoryFilterParams.dateTo"
                    class="select--date"
                    type="date"
                    :placeholder="$t('ЭраНезависимости.датаДо')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <button @click="auditoryFilter(true)" style="background-color: #F2A072;" class="age__clear">
                {{ $t('МедальЕлбасы.Сбросить') }}
              </button>
            </div>

          </div>
          <div class="applications__row">
            <div class="applications__chart">
              <div class="game-card">
                <div class="game-card-title"> {{$t('ЭраНезависимости.Пользователей')}}</div>
                <div class="game-card-count">{{ total_count | Numerize}}</div>
              </div>
              <div class="game-card-chart">
                <div class="game-chart">
                  <apexchart v-if="!genderLoad" width="360" height="398" type="donut" :options="chartOptionsGender"
                             :series="seriesGender"></apexchart>
                </div>
                <div class="game-chart-line"></div>
                <div class="game-chart">
                  <apexchart v-if="!projectLoad" width="360" height="398" type="donut" :options="chartOptionsProject"
                             :series="seriesProject"></apexchart>
                </div>
              </div>
            </div>

            <div style="height: 761px" class="applications__card">
              <div class="applications__title-row">
                <div class="applications__title">
                  №
                </div>
                <div class="applications__title">
                  {{$t('МедальЕлбасы.Регион')}}
                </div>
                <div class="applications__title">
                  {{$t('МедальЕлбасы.Количество')}}
                </div>
              </div>
              <div class="applications__list" style="height: 700px">
                <div @click="reg.total!=0 ?regionSwitch(reg.id):0" v-for="reg in  ageProject" class="panel panel-default mb-3 " v-bind:class="{'panel-disabled': reg.total==0 }"
                     :key="reg.id">
                  <div style="position: relative" class="panel-heading">
                    <div class="applications__res-row panel-title collapsed"
                         v-bind:class="{ 'activePanel': regionTogle==reg.id ,'panel-disabled': reg.total==0}" data-toggle="collapse"
                         data-target="#collapseOne">
                      <div class="applications__num " v-bind:class="{ 'activePanel': regionTogle==reg.id }">
                        {{ reg.id }}
                      </div>
                      <div class="applications__region " v-bind:class="{ 'activePanel': regionTogle==reg.id }">
                        <span v-if="locale=='ru'">{{ reg.title_ru }}</span>
                        <span v-if="locale=='kz'">{{ reg.title_kz }}</span>
                      </div>
                      <div class="applications__amount" v-bind:class="{ 'activePanel': regionTogle==reg.id }">
                        {{ reg.total }}
                      </div>
                    </div>
                  </div>

                  <div class="panel-collapse">
                    <div class="panel-body" v-if="reg.id ==regionTogle">
                      <div class="applications__panel-res">
                        <div class="d-flex panel-collapse">
                        <div style="min-width: 60px"   v-for="age in reg.ages" class="d-flex flex-column align-items-center margin-1" :key="age.age">
                          <div class="mb-3">
                            {{ age.age }} {{$t('МедальЕлбасы.лет')}}
                          </div>
                          <div>
                            {{ age.total_users }}
                          </div>
                        </div>
                        <div style="min-width: 116px"  class="d-flex flex-column align-items-center margin-1" >
                          <div class="mb-3">
                            {{$t('ЭраНезависимости.НеУказано')}}
                          </div>
                          <div>
                            {{ reg.emptyUsers }}
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mb-statistic">
        <div class="container">
          <div class="filter-game-wrap">
            <div class="title">
              Статистика
            </div>
            <div  v-if="locale=='ru'" class="select select--region ml-5">
              <el-select
                  @change="statsFilter(false)"
                  :placeholder="$t('Qabilet.Регион')"
                  v-model="statsFilterParams.region"
                  filterable>
                <el-option
                    v-for="item in regions"
                    :key="item.id"
                    :label="item.title_ru"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div v-if="locale=='kz'" class="select select--region ml-5">
              <el-select
                  @change="statsFilter(false)"
                  :placeholder="$t('Qabilet.Регион')"
                  v-model="statsFilterParams.region"
                  filterable>
                <el-option
                    v-for="item in regions"
                    :key="item.id"
                    :label="item.title_kz"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="select select--region">
              <el-select
                  @change="statsFilter(false)"
                  v-model="statsFilterParams.ageFrom"
                  :placeholder="$t('ЭраНезависимости.возрастОт')"
                  filterable>
                <el-option
                    v-for="item in 100"
                    :key="item+2"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="select select--region">
              <el-select
                  @change="statsFilter(false)"
                  v-model="statsFilterParams.ageTo"
                  :placeholder="$t('ЭраНезависимости.возрастДо')"
                  filterable>
                <el-option
                    v-for="item in 100"
                    :key="item-2"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="select select--date select--region">
              <el-date-picker
                  @change="statsFilter(false)"
                  v-model="statsFilterParams.dateFrom"
                  class="select--date"
                  type="date"
                  :placeholder="$t('ЭраНезависимости.датаОт')"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              />
            </div>
            <div class="select select--date select--region">
              <el-date-picker
                  @change="statsFilter(false)"
                  v-model="statsFilterParams.dateTo"
                  class="select--date"
                  type="date"
                  :placeholder="$t('ЭраНезависимости.датаДо')"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              />
            </div>
            <button @click="statsFilter(true)" style="background-color: #F2A072;" class="age__clear">
              {{ $t('МедальЕлбасы.Сбросить') }}
            </button>
          </div>
          <div class="game-stats-wrapper">
            <div class="game-stats-wrapper-cards">
              <div class="game-card-stats">
                <div class="game-card-title"> {{$t('ЭраНезависимости.СредняяОценка')}}</div>
                <div class="game-card-count">{{ revCounters.average | Numerize}}</div>
              </div>
              <a download href="reviews_export_1662010678.xlsx" class="game-card-stats">
                <div class="game-card-title">{{$t('ЭраНезависимости.Поделилось')}}</div>
                <div class="game-card-count">{{ revCounters.count_reviews | Numerize }}</div>
              </a>
            </div>
            <div class="game-stats-wrapper-table">
              <div class="mb-rating__table-block mb-rating__table-block--schools" style="margin-bottom: 40px">
                <table class="rating-table table table-striped rating-table--schools">
                  <thead>
                  <tr>
                    <th scope="col" class="rating-table__header">№</th>
                    <th scope="col" class="rating-table__header"> {{$t('МедальЕлбасы.ФИО')}}</th>
                    <th scope="col" class="rating-table__header"> {{$t('Qabilet.Регион')}}</th>
                    <th scope="col" v-bind:class="{ 'table-sort-active': this.sort=='-countAge' }" class="rating-table__header table-sort"  @click="filterTable('age')"> {{$t('birgeoqy.Возраст')}}</th>
                    <th scope="col" v-bind:class="{ 'table-sort-active': this.sort=='-countWins'}" class="rating-table__header table-sort"  @click="filterTable('win')"> {{$t('ЭраНезависимости.Побед')}}</th>
                    <th scope="col" v-bind:class="{ 'table-sort-active': this.sort=='-countLose'}" class="rating-table__header table-sort"  @click="filterTable('lose')"> {{$t('ЭраНезависимости.Поражений')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(user,index) in users" :key="user.id">
                    <td scope="row">{{ index + 1 }}</td>
                    <td>{{ user.name }}</td>
                    <td v-if="locale=='ru'"><span v-if="user.area">{{ user.area.title_ru }}</span></td>
                    <td v-if="locale=='kz'"><span v-if="user.area">{{ user.area.title_kz }}</span></td>
                    <td>{{ user.age }}</td>
                    <td>{{ user.count_wins }}</td>
                    <td>{{ user.count_lose }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <el-pagination
                  :pager-count="11"
                  :current-page='meta.currentPage'
                  @current-change="paginateUser"
                  layout="prev, pager, next"
                  :total="meta.pageCount*meta.perPage"
            >
              </el-pagination>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>


import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  name: 'Game',
  data() {
    return {
      sort:'',
      meta:{
        currentPage: 0,
        pageCount: 0,
        perPage: 0,
        totalCount: 0
      },
      auditoryFilterParams: {
        region: '',
        ageTo: '',
        ageFrom: '',
        dateFrom: '',
        dateTo: '',
      },
     statsFilterParams: {
        region: '',
        ageTo: '',
        ageFrom: '',
        dateFrom: '',
        dateTo: '',
      },
      regionTogle: 0,
      locale: this.$i18n.locale,
      total_count: 0,
      regions: [],
      projects: [],
      ageProject: [],
      counters: {},
      revCounters: {
        average: 0,
        count_reviews: 0
      },
      users: [],
      projectLoad: true,
      genderLoad: true,
      seriesProject: [],
      seriesGender: [],
      chartOptionsGender: {
        labels: [this.$t('МедальЕлбасы.мужской'), this.$t('МедальЕлбасы.женский')],
        colors: ['#094174', '#E37233', '#F2A072', '#215B90',],
        legend: {
          show: true,
          position: 'bottom',

        },
        chart: {
          height: 350,
          type: 'donut',
        },
        title: {
          text: this.$t('МедальЕлбасы.Пол'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },

        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
      },
      chartOptionsProject: {
        labels: [],
        colors: ['#094174', '#E37233', '#F2A072', '#215B90',],
        legend: {
          show: true,
          position: 'bottom',

        },
        chart: {
          height: 350,
          type: 'donut',
        },
        title: {
          text: this.$t('ЭраНезависимости.УчастиеВпроектах'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
      },
    }
  },
  mounted() {
    this.$http.get(window.independenceGame + '/v1/users?per-page=10')
        .then((res) => {
          this.users = res.body.items
          this.meta=res.body._meta
        })
    this.$http.get(window.independenceGame + '/v1/dictionaries/country?expand=areas')
        .then((res) => {
          this.regions = res.body[0].areas
        })
    this.$http.get(window.independenceGame + '/v1/users/reviews/counters')
        .then((res) => {
          this.revCounters = res.body
        })
    this.$http.get(window.independenceGame + '/v1/users/age/gender')
        .then((res) => {
          var totalUsers;
          var emptyUsers=0
          this.ageProject = res.body.regions
          for (var i = 0; i < this.ageProject.length; i++) {
            totalUsers = 0
            emptyUsers=0
            for (var x = 0; x < this.ageProject[i].ages.length; x++) {
              if(this.ageProject[i].ages[x].age<51){
                totalUsers = totalUsers + Number(this.ageProject[i].ages[x].total_users)
              }
              else{
                emptyUsers= emptyUsers+Number(this.ageProject[i].ages[x].total_users)
              }
            }
            this.ageProject[i].total = totalUsers+emptyUsers
            this.ageProject[i].emptyUsers=emptyUsers
          }
          for ( i = 0; i < this.ageProject.length; i++) {
            var indexClear=[]
            for ( x = 0; x < this.ageProject[i].ages.length; x++) {
              if(this.ageProject[i].ages[x].age>51){
                indexClear.push(x)
              }
            }
            this.ageProject[i].ages.splice(indexClear[0],indexClear[indexClear.length-1])



          }

        })
    this.$http.get(window.independenceGame + '/v1/users/projects')
        .then((res) => {
          this.projects = res.body
          for (var i = 0; i < res.body.length; i++) {
            this.seriesProject.push(res.body[i].count_users)
            this.chartOptionsProject.labels.push(res.body[i].title_ru)
          }

          this.projectLoad = false


        })
    this.$http.get(window.independenceGame + '/v1/users/counters')
        .then((res) => {
          this.seriesGender = [res.body.count_male, res.body.count_female]
          this.counters = res.body
          this.total_count = res.body.count_users
          this.genderLoad = false
        })

  },
  filters: {
    Numerize: function (value) {
      if (value == 0) return '0'
      if (!value) return ''
      if (value <= 999) return value
      return value.toLocaleString().replace(',', ' ')
    }
  },
  watch: {},

  methods: {
    filterTable(sort){
     switch (sort){
       case 'age':
         if(this.sort=='-countAge'){
           this.sort='countAge'
         }
         else {
           this.sort='-countAge'
         }
         break;
       case 'win':
         if(this.sort=='-countWins'){
           this.sort='countWins'
         }
         else {
           this.sort='-countWins'
         }
         break;
       case 'lose':
         if(this.sort=='-countLose'){
           this.sort='countLose'
         }
         else {
           this.sort='-countLose'
         }
         break;
     }
      this.$http.get(window.independenceGame + '/v1/users?per-page=10&sort='+this.sort)
          .then((res) => {
            this.users = res.body.items
            this.meta=res.body._meta
          })
    },
    paginateUser(page){
      this.$http.get(window.independenceGame + '/v1/users?per-page=10&page='+page)
          .then((res) => {
            this.users = res.body.items
            this.meta=res.body._meta
          })
    },
    statsFilter(clear=false){
      if (clear == true) {
        this.statsFilterParams = {
          region: '',
          ageTo: '',
          ageFrom: '',
          dateFrom: '',
          dateTo: '',
        }
      }
      var query = `?area_id=${this.statsFilterParams.region}&ageFrom=${this.statsFilterParams.ageFrom}&ageTo=${this.statsFilterParams.ageTo}&dateFrom=${this.statsFilterParams.dateFrom}&dateTo=${this.statsFilterParams.dateTo}`
      var queryRange = `?dateFrom=${this.statsFilterParams.dateFrom}&dateTo=${this.statsFilterParams.dateTo}`

      this.$http.get(window.independenceGame + '/v1/users'+query)
          .then((res) => {
            this.users = res.body.items
            this.meta=res.body._meta
          })
      this.$http.get(window.independenceGame + '/v1/users/reviews/counters'+queryRange)
          .then((res) => {
            this.revCounters = res.body
          })
    },
    auditoryFilter(clear = false) {
      if (clear == true) {
        this.auditoryFilterParams = {
          region: '',
          ageTo: '',
          ageFrom: '',
          dateFrom: '',
          dateTo: '',
        }
      }
      var query = `?area_id=${this.auditoryFilterParams.region}&ageFrom=${this.auditoryFilterParams.ageFrom}&ageTo=${this.auditoryFilterParams.ageTo}&dateFrom=${this.auditoryFilterParams.dateFrom}&dateTo=${this.auditoryFilterParams.dateTo}`
      this.$http.get(window.independenceGame + `/v1/users/age/gender${query}`)
          .then((res) => {
            var totalUsers;
            this.ageProject = res.body.regions
            for (var i = 0; i < this.ageProject.length; i++) {
              totalUsers = 0
              for (var x = 0; x < this.ageProject[i].ages.length; x++) {
                totalUsers = totalUsers + Number(this.ageProject[i].ages[x].total_users)
              }
              this.ageProject[i].total = totalUsers
            }

          })
      this.genderLoad = true
      this.$http.get(window.independenceGame + `/v1/users/counters${query}`)
          .then((res) => {
            this.seriesGender = [res.body.count_male, res.body.count_female]
            this.counters = res.body
            this.total_count = res.body.count_users
            this.genderLoad = false
          })
      this.projectLoad = true
      this.seriesProject=[]
      this.chartOptionsProject.labels=[]
      this.$http.get(window.independenceGame + `/v1/users/projects${query}`)
          .then((res) => {
            this.projects = res.body
            for (var i = 0; i < res.body.length; i++) {
              this.seriesProject.push(res.body[i].count_users)
              this.chartOptionsProject.labels.push(res.body[i].title_ru)
            }
            this.projectLoad = false
          })
    },
    regionSwitch(id) {
      if (this.regionTogle == id) {
        this.regionTogle = 0
      } else {
        this.regionTogle = id
      }
    },
    changeLang() {
      localStorage.setItem('locale', this.locale);
      this.$i18n.locale = this.locale;
      this.$router.go()
    },

  }
}
</script>
<style lang="css" scoped>
.applications__panel-res{
  justify-content: start;
}
.margin-1{
  margin: 1rem;
}
.el-pagination{
  margin: auto;
}
.panel-title::after {
  color: unset;
}

.activePanel {
  background: #F1F8FE !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #215B90!important;
}

.tasks__pagination .page-link {
  color: white;
  background: #74B2F0;
}

.tasks__pagination .page-link.active {
  background: #eff7ff;
  color: #2091f9;
}

.loader {
  background: conic-gradient(#74B2F0, transparent) !important;
}

.panel-collapse{
  padding-bottom: 20px;
  overflow: auto;}

.panel-collapse::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #FFF2EB;
}

.panel-collapse::-webkit-scrollbar {
  height: 8px;
  background: rgba(15, 61, 95, 0.15);
}

.panel-collapse::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #215B90;
}
.applications__list::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #FFF2EB;
}

.applications__list::-webkit-scrollbar {
  width: 8px;
  background: rgba(15, 61, 95, 0.15);
}

.applications__list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #215B90;
}
.panel{
  user-select: none;
}
.rating-table__header{
  min-width: 152px!important;
  position: relative;
}
@media (max-width: 1564px) {
  .game-chart{
    width: fit-content;
    margin: auto;
  }
  .game-card{
    padding: 62px 0;
  }
  .game-card-chart{
    padding: 0 0 1rem;
    height: max-content;
    flex-direction: column;
  }
  .applications__card{
    margin-bottom: auto;
  }
  .select{
    margin: 1rem;
  }
  .title{
    margin: 1rem auto 1rem 1rem;
  }
  .age__clear{
    width: 181px;
   margin: 1rem;
  }
  .filter-game-wrap{
    justify-content: end;
    flex-wrap: wrap;
    width: 100%;
  }
}
@media (max-width: 1196px) {
  .applications__chart{
    width: 100%;
  }
  .applications__row{
    flex-direction: column-reverse;
  }
  .applications__card{
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
  }
  .applications__region{
    margin: auto;
  }
}
@media (max-width: 991px) {
  .game-stats-wrapper{
    flex-direction: column;
  }
  .game-stats-wrapper-table{
    max-width: 100%;
  }
  .game-stats-wrapper-cards{
    width: 100%;
  }
}
</style>
